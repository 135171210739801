import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CircularProgress,
} from "@mui/material";
import contactImage1 from "../assets/tommy-di-napoli-tommy-con-prosciutto-olasz-pizza-budapest.jpg";
import contactImage2 from "../assets/foodora.jpg";
import contactImage3 from "../assets/tommy-di-napoli-riberella-olasz-pizza-budapest.jpg";
import contactImage4 from "../assets/tommy-di-napoli-al-filetto-olasz-pizza-budapest.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ContactPage = () => {
  const [isImage1Loading, setImage1Loading] = useState(true);
  const [isImage2Loading, setImage2Loading] = useState(true);
  const [isImage3Loading, setImage3Loading] = useState(true);
  const [isImage4Loading, setImage4Loading] = useState(true);

  const ImageWithLoading = ({ src, alt, isLoading, setLoading }) => (
    <div style={{ position: "relative" }}>
      {isLoading && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
      <img
        src={src}
        alt={alt}
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
        style={{
          display: isLoading ? "none" : "block",
          width: "100%",
          height: "auto",
        }}
      />
    </div>
  );

  return (
    <Container maxWidth='lg'>
      {/* Background Image Section 1 */}
      <Grid container spacing={2} justifyContent='center' sx={{ my: 4 }}>
        <Grid item xs={12} md={6}>
          <Card>
            <ImageWithLoading
              src={contactImage1}
              alt='tommy-di-napoli-olasz-pizzeria-italian-pizza-in-budapest'
              isLoading={isImage1Loading}
              setLoading={setImage1Loading}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <ImageWithLoading
              src={contactImage2}
              alt='order-online-pizza-delivery-best-pizza-in-budpest-pizza-hazhozszallitas-legjobb-pizzeria'
              isLoading={isImage2Loading}
              setLoading={setImage2Loading}
            />
          </Card>
        </Grid>
      </Grid>
      {/* Contact Information */}
      <Grid
        container
        spacing={3}
        sx={{ my: 4, alignItems: "center", justifyContent: "center" }}
      >
        <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
          <a
            href='tel:+36303313811'
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <PhoneIcon sx={{ fontSize: 40 }} />

            <Typography variant='h6'>+36 30 331 3811</Typography>
          </a>
        </Grid>
        <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
          <a
            href='mailto:tommy@tommydinapoli.com'
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <EmailIcon sx={{ fontSize: 40 }} />

            <Typography variant='h6'>tommy@tommydinapoli.com</Typography>
          </a>
        </Grid>
        <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
          <LocationOnIcon sx={{ fontSize: 40 }} />
          <Typography variant='h6'>Teréz körút 37, 1067 Budapest</Typography>
        </Grid>
      </Grid>

      {/* Background Image Section 2 */}
      <Grid container spacing={2} justifyContent='center' sx={{ my: 4 }}>
        <Grid item xs={12} md={6}>
          <Card>
            <ImageWithLoading
              src={contactImage4}
              alt='tommy-di-napoli-olasz-pizzeria-italian-pizza-in-budapest'
              isLoading={isImage3Loading}
              setLoading={setImage3Loading}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <ImageWithLoading
              src={contactImage3}
              alt='order-online-authentic-Italian-pizza-in-Tommy-Di-Napoli-By-Gennaro'
              isLoading={isImage4Loading}
              setLoading={setImage4Loading}
            />
          </Card>
        </Grid>
      </Grid>
      {/* Background Image Section 3 */}
    </Container>
  );
};

export default ContactPage;
