import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ClerkProviderWithRoutes } from "./AppRoutes";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className='App'>
      <Router>
        <ClerkProviderWithRoutes />
      </Router>
    </div>
  );
}

export default App;
