import React from "react";
import {
  Container,
  Grid,
  IconButton,
  Link,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { ReactComponent as Logo } from "../assets/Tommy-logo.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
const theme = createTheme({
  palette: {
    primary: {
      main: "#b71c1c",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const Footer = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Paper
        component='footer'
        sx={{
          backgroundColor: "primary.main",
          color: "secondary.main",
          marginTop: "auto",
          padding: { xs: "1rem 0.5rem", sm: "1rem 0" },
        }}
      >
        <Container maxWidth='lg'>
          <Grid
            container
            spacing={1}
            alignItems='center'
            justifyContent='space-around'
          >
            <Grid item xs>
              <Logo style={{ fill: "#fff", width: 40, height: "auto" }} />{" "}
              {/* Adjusted logo size */}
              <Typography
                variant='caption'
                display={{ xs: "none", sm: "block" }}
              >
                &copy; {new Date().getFullYear()} {t("brand")}
              </Typography>
            </Grid>

            <Grid item xs display='flex' justifyContent='center'>
              <Link
                href='/rolunk'
                color='inherit'
                underline='hover'
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                {t("about_us")}
              </Link>
            </Grid>

            <Grid item xs display='flex' justifyContent='center'>
              <Box
                display='flex'
                flexDirection={{ xs: "row", sm: "column" }}
                alignItems='center'
              >
                <HomeIcon sx={{ mr: 1 }} />
                <Typography sx={{ textAlign: "center" }}>
                  Teréz körút 37
                </Typography>
              </Box>
            </Grid>

            <Grid item xs display='flex' justifyContent='center'>
              <IconButton color='inherit' href='tel:+36303313811'>
                <PhoneIcon />
              </IconButton>
              <IconButton color='inherit' href='mailto:tommy@tommydinapoli.com'>
                <EmailIcon />
              </IconButton>
              <IconButton
                color='inherit'
                href='https://www.facebook.com/tommydinapolibygennaro'
                target='_blank'
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                color='inherit'
                href='https://www.instagram.com/tommydinapolibygennaro/'
                target='_blank'
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                color='inherit'
                href='https://www.tiktok.com/@tommydinapolibygennaro'
                target='_blank'
              >
                <MusicNoteIcon />
              </IconButton>
            </Grid>

            <Grid item xs display={{ xs: "block", sm: "none" }}>
              <Typography variant='caption' sx={{ textAlign: "center" }}>
                &copy; {new Date().getFullYear()} {t("brand")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </ThemeProvider>
  );
};

export default Footer;
