import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#b71c1c",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});
const DrinkCard = ({ drink }) => {
  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          display: "flex",
          width: "100%",
          height: 120,
          bgcolor: "background.default",
          m: 2,
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <CardActionArea
          sx={{
            display: "flex",
            justifyContent: "start",
            width: "100%",
          }}
        >
          {/* Left side: Image */}
          <CardMedia
            component='img'
            sx={{
              width: 120,
              height: "100%",
              borderRadius: "inherit",
            }}
            image={drink.image}
            alt={drink.alt}
          />
          {/* Right side: Content */}
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              flexGrow: 1,
              "&:last-child": {
                paddingBottom: "16px",
              },
            }}
          >
            <Typography
              variant='h6'
              color='secondary'
              sx={{ fontWeight: "bold" }}
            >
              {drink.name}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {drink.volume}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {`${drink.price.toLocaleString("hu-HU")} Ft`}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </ThemeProvider>
  );
};

export default DrinkCard;
