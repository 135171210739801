import React from "react";
import {
  Typography,
  Grid,
  Paper,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import PizzaIcon from "@mui/icons-material/LocalPizza";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EventIcon from "@mui/icons-material/Event";
import CountUp from "react-countup";
import magyar_offer from "../assets/magyar_offer.png";
import angol_offer from "../assets/angol_offer.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import pizzaBackground from "../assets/pizza_background.jpg";
import fabio_gennaro from "../assets/fabio_gennaro.png";
import tommy_logo from "../assets/Tommy-logo.png";
import touristImage1 from "../assets/turistak_pizza.jpg";
import touristImage2 from "../assets/turistak2_pizza.jpg";

const CustomIcon = ({
  $hover,
  $getDimensions,
  $dimensionKey,
  $geoService,
  $onMouseAllow,
  $prerender,
  ...props
}) => {
  return <LocationOnIcon {...props} />;
};
const HomePage = () => {
  const location = { lat: 47.50771018528929, lng: 19.06039642526882 };
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API;
  const { t } = useTranslation();
  const { language } = useParams();
  return (
    <Container maxWidth='lg' sx={{ flexGrow: 1 }}>
      {/* Banner Section */}
      <Paper
        elevation={4}
        sx={{
          my: 4,
          mx: "auto",
          width: "100%",
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            },
            "& > *": {
              position: "relative",
              zIndex: 2,
            },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "right",
            width: "100%",
            p: 2,
            backgroundImage: `url(${fabio_gennaro})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          }}
        >
          <Typography
            variant='h5'
            textAlign='center'
            sx={{
              marginRight: { md: 2 },
              zIndex: 2,
            }}
          >
            <b>{t("homepage_szoveg")}</b>
          </Typography>
          <img
            src={tommy_logo}
            alt='olasz-pizza-fabio-gennaro-tommy-di-napoli-etterem'
            style={{
              width: "100%",
              maxWidth: "360px",
              height: "auto",
              marginBottom: { xs: "20px", md: 0 },
              textAlign: "right",
              zIndex: 2,
            }}
          />
        </Box>
      </Paper>
      <Grid container spacing={2} justifyContent='center' sx={{ my: 4 }}>
        {/* Image 1 */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component='img'
              image={touristImage1}
              alt='guests-enjoying-authentic-Italian-pizza-in-Tommy-Di-Napoli-By-Gennaro'
            />
          </Card>
        </Grid>

        {/* Image 2 */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component='img'
              image={touristImage2}
              alt='more-guests-enjoying-authentic-Italian-pizza-in-Tommy-Di-Napoli-By-Gennaro'
            />
          </Card>
        </Grid>
      </Grid>
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            },
            "& > *": {
              position: "relative",
              zIndex: 2,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
            backgroundImage: `url(${pizzaBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          }}
        >
          {language === "hu" ? (
            <img
              src={magyar_offer}
              alt='legjobb-autentikus-olasz-pizza-budapesten-akcio'
              style={{
                width: "100%",
                maxWidth: "360px",
                height: "auto",
                marginBottom: "20px",
              }}
            />
          ) : (
            <img
              src={angol_offer}
              alt='best-authentic-pizzeria-in-budapest-discount'
              style={{
                width: "100%",
                maxWidth: "360px",
                height: "auto",
                marginBottom: "20px",
              }}
            />
          )}
          <Typography variant='h5' textAlign='center'>
            <b>{t("udvozlo_nyitas")}</b>
          </Typography>
        </Box>
      </Paper>
      {/* Banner Section */}

      {/* Numbers Section */}
      <Grid container spacing={2} justifyContent='center' sx={{ my: 4 }}>
        <Grid
          item
          xs={12}
          sm={4}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <PizzaIcon sx={{ fontSize: 60 }} />
          <CountUp start={0} end={15000} duration={2.75} />
          <Typography>{t("pizzak_keszitve")}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <EmojiPeopleIcon sx={{ fontSize: 60 }} />
          <CountUp start={0} end={10000} duration={2.75} />
          <Typography>{t("boldog_vendeg")}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <EventIcon sx={{ fontSize: 60 }} />
          <CountUp start={0} end={1} duration={2.75} />
          <Typography>{t("evek_ota")}</Typography>
        </Grid>
      </Grid>
      {/* Map and Contact Details Section */}
      <Grid container spacing={2} sx={{ my: 4, alignItems: "stretch" }}>
        {/* Map Section */}
        <Grid item xs={12} md={8}>
          <Paper elevation={4} sx={{ position: "relative", height: 400 }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: apiKey }}
              defaultCenter={location}
              defaultZoom={15}
              style={{ height: "100%", width: "100%" }}
            >
              <CustomIcon lat={location.lat} lng={location.lng} />
            </GoogleMapReact>
          </Paper>
        </Grid>

        {/* Contact Details Section */}
        <Grid item xs={12} md={4}>
          <Card
            elevation={4}
            sx={{ display: "flex", flexDirection: "column", height: 400 }}
          >
            <CardContent>
              {/* Contact information here */}
              <Typography variant='h6' gutterBottom>
                {t("contact_us")}
              </Typography>
              <Box display='flex' alignItems='center' my={1}>
                <PhoneIcon sx={{ mr: 1 }} />
                <Typography>
                  <a
                    href='tel:+36303313811'
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    +36 30 331 3811
                  </a>
                </Typography>
              </Box>
              <Box display='flex' alignItems='center' my={1}>
                <EmailIcon sx={{ mr: 1 }} />
                <Typography>
                  <a
                    href='mailto:tommy@tommydinapoli.com'
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    tommy@tommydinapoli.com
                  </a>
                </Typography>
              </Box>
              <Box display='flex' alignItems='center' my={1}>
                <HomeIcon sx={{ mr: 1 }} />
                <Typography>Teréz körút 37, 1067 Budapest</Typography>
              </Box>
              <Box display='flex' alignItems='center' my={1}>
                <EventIcon sx={{ mr: 1 }} />
                <Typography>{t("opening_hours")}</Typography>
              </Box>
              <Box display='flex' flexDirection='column' ml={5}>
                <Typography>{t("nyitva")}</Typography>
                <Typography>{t("zarva")}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
