import React from "react";
import {
  Typography,
  Grid,
  Paper,
  Container,
  Card,
  CardMedia,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import introBackgroundImage from "../assets/delizia-tommy.jpg";
import { useTranslation } from "react-i18next";
import pizzeriaImage from "../assets/turistak_pizza.jpg";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#303030",
    },
    primary: {
      main: "#b71c1c",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});
const AboutUsPage = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth='lg' sx={{ flexGrow: 1, py: 5 }}>
        {/* Introduction Section */}
        <Paper
          elevation={4}
          sx={{
            my: 4,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url(${introBackgroundImage})`,
            backgroundSize: "cover",
            color: "white",
            textShadow: "1px 1px 2px black",
            minHeight: "300px",
          }}
        >
          <Typography variant='h4' textAlign='center' gutterBottom>
            {t("about_us")}
          </Typography>
          <Typography variant='h6' textAlign='center'>
            {t("about_us_desc")}
          </Typography>
        </Paper>

        {/* Our Story Section */}
        <Grid container spacing={4} justifyContent='center' sx={{ my: 4 }}>
          <Grid item xs={12} md={6}>
            <Card elevation={4}>
              <CardMedia
                component='img'
                height='240'
                image={pizzeriaImage}
                alt='tommy-di-napoli-by-gennaro-authentic-italian-pizzeria-budapest'
              />
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display='flex'
            flexDirection='column'
            justifyContent='center'
          >
            <HistoryEduIcon sx={{ fontSize: 60, alignSelf: "center" }} />
            <Typography variant='h5' textAlign='center' gutterBottom>
              {t("our_story")}
            </Typography>
          </Grid>
        </Grid>

        {/* Our Values Section */}
        <Paper
          elevation={4}
          sx={{
            my: 4,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#b71c1c",
          }}
        >
          <RestaurantIcon sx={{ fontSize: 60 }} />
          <Typography variant='h5' textAlign='left'>
            {t("our_values_desc")}
          </Typography>
          <Typography variant='h5' textAlign='left' gutterBottom>
            {t("our_values")}
          </Typography>
        </Paper>

        {/* Meet the Team Section */}

        {/* End of team member Grid item */}
      </Container>
    </ThemeProvider>
  );
};

export default AboutUsPage;
