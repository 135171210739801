import React from "react";
import Grid from "@mui/material/Grid";
import PizzaCard from "../components/PizzaCard";
import DrinkCard from "../components/DrinkCard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import tommy_con_prosciutto_crudo from "../assets/tommy-di-napoli-tommy-con-prosciutto-olasz-pizza-budapest.jpg";
import delizia from "../assets/tommy-di-napoli-delizia-olasz-pizza-budapest.jpg";
import soda from "../assets/oran_soda_.avif";
import pinot from "../assets/pinot.avif";
import motivo from "../assets/motivo.avif";
import water from "../assets/water.avif";
import ice_tea from "../assets/ice_tea.avif";
import cola from "../assets/cola.avif";
import cola_zero from "../assets/cola_zero.avif";
import moretti from "../assets/moretti.avif";
import nduja from "../assets/tommy-di-napoli-nduja-olasz-pizza-budapest.jpg";
import campiana from "../assets/tommy-di-napoli-campiana-olasz-pizza-budapest.jpg";
import cotto_e_funghi from "../assets/tommy-di-napoli-cotto-funghi-olasz-pizza-budapest.jpg";
import posillipo from "../assets/tommy-di-napoli-posillipo-olasz-pizza-budapest.jpg";
import vegetariana from "../assets/tommy-di-napoli-vegetariana-olasz-pizza-budapest.jpg";
import bombetta_di_toto from "../assets/tommy-di-napoli-toto-olasz-pizza-budapest.jpg";
import riberella from "../assets/tommy-di-napoli-riberella-olasz-pizza-budapest.jpg";
import melanzana_con_funghi from "../assets/tommy-di-napoli-melanzana-con-fungi-olasz-pizza-budapest.jpg";
import genovese from "../assets/tommy-di-napoli-genovese-olasz-pizza-budapest.jpg";
import al_filetto from "../assets/tommy-di-napoli-al-filetto-olasz-pizza-budapest.jpg";
import capricciosa from "../assets/tommy-di-napoli-capricciosa-olasz-pizza-budapest.jpg";
import italia from "../assets/tommy-di-napoli-piazza-italia-olasz-pizza-budapest.jpg";
import calzone from "../assets/tommy-di-napoli-calzone-olasz-pizza-budapest.jpg";
import margherita from "../assets/tommy-di-napoli-margherita-olasz-pizza-budapest.jpg";
import conbufala from "../assets/tommy-di-napoli-margherita-con-bufala-olasz-pizza-budapest.jpg";
import marinara from "../assets/tommy-di-napoli-marinara-olasz-pizza-budapest.jpg";
import tommy from "../assets/tommy-di-napoli-tommy-olasz-pizza-budapest.jpg";
import prosciutto_cotto from "../assets/tommy-di-napoli-cotto-olasz-pizza-budapest.jpg";
import tonno_e_cipolla from "../assets/tommy-di-napoli-tonno-olasz-pizza-budapest.jpg";
import formaggi from "../assets/tommy-di-napoli-formaggi-olasz-pizza-budapest.jpg";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#303030",
    },
    primary: {
      main: "#b71c1c",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const MenuPage = () => {
  const { t } = useTranslation();

  const pizzas = [
    {
      id: 1,
      name: "Margherita",
      ingredients: [t("tomato"), t("fior_di_latte"), t("basil")],
      image: margherita,
      price: 3590,
      alt: "tommy-di-napoli-margherita-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 2,
      name: "Margherita Con Bufala",
      ingredients: [t("tomato"), t("bufala"), t("basil")],
      image: conbufala,
      price: "4790",
      alt: "tommy-di-napoli-margherita-bivalymozzarela-olasz-pizza-budapest-italian-pizzeria-bufala",
    },
    {
      id: 3,
      name: "Calzone",
      ingredients: [t("tomato"), t("ricota"), t("fior_di_late"), t("sonka")],
      image: calzone,
      price: "4690",
      alt: "tommy-di-napoli-calzone-olasz-pizza-budapest-italian-pizzeria-calzone",
    },
    {
      id: 4,
      name: "Capricciosa",
      ingredients: [
        t("tomato"),
        t("fior_di_late"),
        t("sonka"),
        t("gomba"),
        t("articsoka"),
        t("fekete_oliva"),
      ],
      image: capricciosa,
      price: 4790,
      alt: "tommy-di-napoli-capricciosa-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 5,
      name: "Piazza Italia",
      ingredients: [
        t("tomato"),
        t("fior_di_late"),
        t("parmai"),
        t("rukkola"),
        t("articsoka"),
        t("parmezan"),
      ],
      image: italia,
      price: 4890,
      alt: "tommy-di-napoli-piazza italia-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 6,
      name: "Riberella",
      ingredients: [
        t("tomato"),
        t("fior_di_late"),
        t("szalami"),
        t("pepperoni"),
      ],
      image: riberella,
      price: 3990,
      alt: "tommy-di-napoli-riberella-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 7,
      name: "Genovese",
      ingredients: [t("pesto"), t("fior_di_late"), t("tejszin"), t("sonka")],
      image: genovese,
      price: 4590,
      alt: "tommy-di-napoli-riberella-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 8,
      name: "Tommy",
      ingredients: [
        t("tomato"),
        t("rukkola"),
        t("bufala"),
        t("koktel_paradicsom"),
      ],
      image: tommy,
      price: 4890,
      alt: "tommy-di-napoli-tommy-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 9,
      name: "Marinara",
      ingredients: [t("tomato"), t("oregano"), t("fokhagyma")],
      image: marinara,
      price: 3190,
      alt: "tommy-di-napoli-marinara-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 10,
      name: "Prosciutto Cotto",
      ingredients: [t("tomato"), t("fior_di_latte"), t("sonka")],
      image: prosciutto_cotto,
      price: 3990,
      alt: "tommy-di-napoli-prosciutto-cotto-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 11,
      name: "Tonno e Cipolla",
      ingredients: [t("tomato"), t("fior_di_latte"), t("tuna"), t("hagyma")],
      image: tonno_e_cipolla,
      price: 4390,
      alt: "tommy-di-napoli-tonno-e-cipolla-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 12,
      name: "4 Formaggi",
      ingredients: [
        t("fior_di_latte"),
        t("gorgonzola"),
        t("fustolt_sajt"),
        t("parmesan"),
      ],
      image: formaggi,
      price: 4590,
      alt: "tommy-di-napoli-4-formaggi-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 13,
      name: "Al Filetto",
      ingredients: [
        t("pesto"),
        t("tejszin"),
        t("fior_di_latte"),
        t("koktel_paradicsom"),
      ],
      image: al_filetto,
      price: 4390,
      alt: "tommy-di-napoli-al-filetto-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 16,
      name: "Posillipo",
      ingredients: [t("fior_di_latte"), t("bufala"), t("parmai")],
      image: posillipo,
      price: 4590,
      alt: "tommy-di-napoli-posillipo-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 20,
      name: "Campiana",
      ingredients: [
        t("tomato"),
        t("fior_di_latte"),
        t("szalami"),
        t("hagyma"),
        t("chili"),
      ],
      image: campiana,
      price: 4590,
      alt: "tommy-di-napoli-campiana-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 14,
      name: "Delizia",
      ingredients: [t("delizia_desc")],
      image: delizia,
      price: 4990,
      alt: "tommy-di-napoli-delizia-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 17,
      name: "Bombetta di Totò",
      ingredients: [t("toto_desc")],
      image: bombetta_di_toto,
      price: 4790,
      alt: "tommy-di-napoli-bombetta-di-toto-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 18,
      name: "Melanzana con Funghi",
      ingredients: [
        t("tomato"),
        t("fior_di_latte"),
        t("padlizsan"),
        t("gomba"),
      ],
      image: melanzana_con_funghi,
      price: 4490,
      alt: "tommy-di-napoli-melanzana-con-funghi-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 15,
      name: "Vegetariana",
      ingredients: [
        t("tomato"),
        t("fior_di_latte"),
        t("gomba"),
        t("articsoka"),
        t("fekete_oliva"),
        t("basil"),
      ],
      image: vegetariana,
      price: 4290,
      alt: "tommy-di-napoli-vegetariana-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 22,
      name: "Tommy con Prosciutto Crudo",
      ingredients: [t("tomato"), t("rukkola"), t("bufala"), t("prosciutto")],
      image: tommy_con_prosciutto_crudo,
      price: 5490,
      alt: "tommy-di-napoli-tommy-con-prosciutto-crudo-olasz-pizza-budapest-italian-pizzeria",
    },

    {
      id: 19,
      name: "Cotto e Funghi",
      ingredients: [t("tomato"), t("fior_di_latte"), t("sonka"), t("gomba")],
      image: cotto_e_funghi,
      price: 4390,
      alt: "tommy-di-napoli-cotto-e-funghi-olasz-pizza-budapest-italian-pizzeria",
    },
    {
      id: 21,
      name: "Nduja calabrese e bufala",
      ingredients: [t("tomato"), t("nduja"), t("bufala"), t("basil")],
      image: nduja,
      price: 5190,
      alt: "tommy-di-napoli-nduja-olasz-pizza-budapest-italian-pizzeria",
    },
  ];
  const wines = [
    {
      id: 3,
      name: "Borgo Molino - Pinot Grigio",
      price: "8450",
      volume: "0.7l",
      image: pinot,
    },
    {
      id: 8,
      name: "Borgo Molino - Motivo",
      price: "7990",
      volume: "0.7l",
      image: motivo,
    },
  ];
  const drinks = [
    {
      id: 1,
      name: "Coca Cola",
      price: "690",
      volume: "330ml",
      image: cola,
    },
    {
      id: 2,
      name: "Coca Cola Zero",
      price: "690",
      volume: "330ml",
      image: cola_zero,
    },

    {
      id: 4,
      name: t("water"),
      price: "490",
      volume: "500ml",
      image: water,
    },
    {
      id: 5,
      name: t("ice_tea"),
      price: "790",
      volume: "500ml",
      image: ice_tea,
    },
    {
      id: 6,
      name: t("soda"),
      price: "790",
      volume: "330ml",
      image: soda,
    },
    {
      id: 7,
      name: "Birra Moretti",
      price: "990",
      volume: "330ml",
      image: moretti,
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary.main' gutterBottom>
            {t("pizzas")}
          </Typography>
        </Grid>
        {pizzas.map((pizza) => (
          <Grid item key={pizza.id} xs={12} sm={6} md={4} lg={3}>
            <PizzaCard pizza={pizza} />
          </Grid>
        ))}

        {/* Wines Section */}
        <Grid item xs={12} id='itallap'>
          <Typography variant='h4' color='primary.main' gutterBottom>
            {t("wines")}
          </Typography>
        </Grid>
        {wines.map((wine) => (
          <Grid item key={wine.id} xs={12} sm={6} md={4} lg={3}>
            <DrinkCard drink={wine} />
          </Grid>
        ))}

        {/* Beverages Section */}
        <Grid item xs={12}>
          <Typography variant='h4' color='primary.main' gutterBottom>
            {t("drinks")}
          </Typography>
        </Grid>
        {drinks.map((drink) => (
          <Grid item key={drink.id} xs={12} sm={6} md={4} lg={3}>
            <DrinkCard drink={drink} />
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};

export default MenuPage;
