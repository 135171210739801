import React from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GalleryPage from "./pages/GalleryPage";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MenuPage from "./pages/MenuPage";
import AboutUsPage from "./pages/AboutUsPage";

import ContactPage from "./pages/ContactPage";
const ALLOWED_LANGUAGES = ["en", "hu"];

export function ClerkProviderWithRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/hu/' />} />
      <Route path='/:language/*' element={<WithLanguageRoutes />} />
      <Route path='*' element={"404 Page not found"} />
    </Routes>
  );
}

export const slugTranslations = {
  contact: {
    hu: "kapcsolat",
    en: "contact",
  },
  about: {
    hu: "rolunk",
    en: "about",
  },
  menu: {
    hu: "etlap",
    en: "menu",
  },
  etlap: {
    hu: "etlap",
    en: "menu",
  },
  rolunk: {
    hu: "rolunk",
    en: "about",
  },
  kapcsolat: {
    hu: "kapcsolat",
    en: "contact-us",
  },
};
export const getSlug = (language, pageIdentifier) => {
  return slugTranslations[pageIdentifier]?.[language] || pageIdentifier;
};
const WithLanguageRoutes = () => {
  const { language } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  React.useEffect(() => {
    if (!ALLOWED_LANGUAGES.includes(language)) {
      navigate(`/hu`);
    } else {
      i18n.changeLanguage(language);
    }
  }, [language, navigate, i18n]);
  return (
    <>
      <Header />
      <Container fluid>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path={getSlug(language, "menu")} element={<MenuPage />} />
          <Route path={getSlug(language, "about")} element={<AboutUsPage />} />
          <Route
            path={getSlug(language, "contact")}
            element={<ContactPage />}
          />
          <Route path='/photoblog' element={<GalleryPage />} />
          <Route path='*' element={"404 Page not found"} />
          {/*<ToastContainer autoClose={1500} />
        
         
          <Route path='menu' element={<StorePage />} />
          <Route path='mostsold' element={<MostSoldPage />} />
          <Route path='search' element={<SearchPage />} />
          <Route path='cart' element={<CartPage />} />
          <Route path='checkout' element={<CheckoutPage />} />
          <Route
            path='product/:productId/:productSlug'
            element={<ProductPage />}
          />
          <Route path='support' element={<SupportPage />} />
          <Route path='newest' element={<NewestPage />} />
          <Route path='sign-in/*' element={<CenteredSignIn />} />
          <Route path='sign-up/*' element={<CenteredSignUp />} />*/}
        </Routes>
      </Container>
      <Footer />
    </>
  );
};
