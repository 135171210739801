import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Drawer,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { getSlug } from "../AppRoutes";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import PhoneIcon from "@mui/icons-material/Phone";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import MenuIcon from "@mui/icons-material/Menu";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LanguageSelector from "./LanguageSelector";
import logo from "../assets/Tommy-logo.svg";
import "../styles/styling.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#b71c1c",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});
const responsiveStyles = {
  "@media (max-width: 680px)": {
    ".responsive-hide": {
      display: "none",
    },
  },
};
const Header = () => {
  const { t } = useTranslation();
  const { language } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deliveryAnchorEl, setDeliveryAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const socialButtons = (
    <>
      <IconButton
        color='inherit'
        href='https://www.facebook.com/tommydinapolibygennaro'
        target='_blank'
      >
        <FacebookIcon />
      </IconButton>
      <IconButton
        color='inherit'
        href='https://www.instagram.com/tommydinapolibygennaro/'
        target='_blank'
      >
        <InstagramIcon />
      </IconButton>
      <IconButton
        color='inherit'
        href='https://www.tiktok.com/@tommydinapolibygennaro'
        target='_blank'
      >
        <MusicNoteIcon />
      </IconButton>
    </>
  );
  const localizedPath = (pageIdentifier, hyper = "") => {
    if (hyper) {
      return `/${language}/${getSlug(language, pageIdentifier)}#${hyper}`;
    }
    return `/${language}/${getSlug(language, pageIdentifier)}`;
  };
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const renderMobileMenu = () => (
    <Drawer anchor='right' open={mobileMenuOpen} onClose={toggleMobileMenu}>
      <List>
        {/* List of menu items */}
        <ListItem
          button
          component={Link}
          to={localizedPath("menu")}
          onClick={toggleMobileMenu}
        >
          {t("foods")}
        </ListItem>
        <ListItem
          button
          component={HashLink}
          to={localizedPath("menu", "itallap")}
          onClick={toggleMobileMenu}
        >
          {t("bevarages")}
        </ListItem>
        <ListItem
          button
          component={Link}
          to={localizedPath("about")}
          onClick={toggleMobileMenu}
        >
          {t("about_us")}
        </ListItem>
        <ListItem
          button
          component={Link}
          to={`/${language}/photoblog`}
          onClick={toggleMobileMenu}
        >
          {t("gallery")}
        </ListItem>
        <ListItem
          button
          component={Link}
          to={localizedPath("contact")}
          onClick={toggleMobileMenu}
        >
          {t("contact_us")}
        </ListItem>
        {isMobile && (
          <List>
            <ListItem>{socialButtons}</ListItem>
          </List>
        )}
        {/* Add other items as needed */}
      </List>
      <Divider />
      {/* Delivery links */}
      <List>
        <ListItem
          button
          component='a'
          href={generateDeliveryUrl("wolt")}
          target='_blank'
          rel='noopener noreferrer'
        >
          Wolt
        </ListItem>
        <ListItem
          button
          component='a'
          href={generateDeliveryUrl("foodora")}
          target='_blank'
          rel='noopener noreferrer'
        >
          Foodora
        </ListItem>
      </List>
    </Drawer>
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeliveryClick = (event) => {
    setDeliveryAnchorEl(event.currentTarget);
  };

  const handleDeliveryClose = () => {
    setDeliveryAnchorEl(null);
  };
  const currentPageIdentifier = window.location.pathname.split("/")[2];
  const generateDeliveryUrl = (platform) => {
    const baseUrlMap = {
      foodora: "https://www.foodora.hu/",
      wolt: "https://wolt.com/",
    };

    const pathMap = {
      foodora: {
        en: "en/restaurant/wkqr/tommy-di-napoli-by-gennaro",
        hu: "restaurant/wkqr/tommy-di-napoli-by-gennaro",
      },
      wolt: {
        en: "en/hun/budapest/restaurant/tommy-di-napoli-pest",
        hu: "hu/hun/budapest/restaurant/tommy-di-napoli-pest",
      },
    };

    return `${baseUrlMap[platform]}${pathMap[platform][language]}`;
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position='static' color='primary' sx={responsiveStyles}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={toggleMobileMenu}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='home'
            component={Link}
            to={`/${language}`}
            style={{
              color: "inherit",
              textDecoration: "none",
              marginRight: "auto",
            }}
          >
            <img
              src={logo}
              style={{ maxHeight: "40px" }}
              alt='Tommy Di Napoli by Gennaro'
            />
          </IconButton>

          <Button
            color='inherit'
            className='responsive-hide'
            onClick={handleMenuClick}
          >
            {t("menu")}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to={localizedPath("menu")}
            >
              {t("foods")}
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              component={HashLink}
              to={localizedPath("menu", "itallap")}
            >
              {t("bevarages")}
            </MenuItem>
          </Menu>

          <Button
            color='inherit'
            className='responsive-hide'
            component={Link}
            to={localizedPath("about")}
          >
            {t("about_us")}
          </Button>

          <Button
            color='inherit'
            className='responsive-hide'
            component={Link}
            to={`/${language}/photoblog`}
          >
            {t("gallery")}
          </Button>

          <IconButton
            color='inherit'
            component={Link}
            to={localizedPath("contact")}
          >
            <PhoneIcon />
          </IconButton>

          <IconButton color='inherit' onClick={handleDeliveryClick}>
            <DeliveryDiningIcon />
          </IconButton>
          <Menu
            anchorEl={deliveryAnchorEl}
            open={Boolean(deliveryAnchorEl)}
            onClose={handleDeliveryClose}
          >
            <MenuItem onClick={handleDeliveryClose}>
              <a
                href={generateDeliveryUrl("wolt")}
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Wolt
              </a>
            </MenuItem>
            <MenuItem onClick={handleDeliveryClose}>
              <a
                href={generateDeliveryUrl("foodora")}
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Foodora
              </a>
            </MenuItem>
          </Menu>

          {isMobile ? null : socialButtons}
          <LanguageSelector currentPage={currentPageIdentifier} />
        </Toolbar>
      </AppBar>
      {renderMobileMenu()}
    </ThemeProvider>
  );
};

export default Header;
