import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ReactCountryFlag from "react-country-flag";
import { getSlug } from "../AppRoutes";

const FlagWithRef = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <ReactCountryFlag {...props} />
  </div>
));

const StyledReactCountryFlag = styled(FlagWithRef)`
  cursor: pointer;
  font-size: 1.7em;
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 0.2em;
  margin: 0.2em;
  background-color: #fff;
  box-shadow: 0 0 0.5em 0.1em rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [selectedFlag, setSelectedFlag] = useState("HU");

  const changeLanguage = (language, flag) => {
    i18n.changeLanguage(language);
    setSelectedFlag(flag);
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split("/").filter(Boolean);
    const pageIdentifier = pathParts[1];

    const translatedSlug = getSlug(language, pageIdentifier);

    let newPath = "";
    if (translatedSlug) {
      newPath = `/${language}/${translatedSlug}`;
    } else {
      newPath = `/${language}/`;
    }

    navigate(newPath);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={StyledReactCountryFlag}
        countryCode={selectedFlag}
        svg
        id='dropdown-basic'
      />
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage("hu", "HU")}>
          <ReactCountryFlag countryCode='HU' svg /> Magyar
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage("en", "GB")}>
          <ReactCountryFlag countryCode='GB' svg /> English
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelector;
