import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Modal,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#b71c1c",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxWidth: "80%",
  bgcolor: "background.paper",
  border: "2px solid #b71c1c",
  boxShadow: 24,
  p: 4,
};

const PizzaCard = ({ pizza }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          maxWidth: 345,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          bgcolor: "background.default",
          m: 2,
          boxShadow: 3,
          borderRadius: "16px",
          transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.03)",
            boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
          },
        }}
      >
        <CardActionArea onClick={handleOpen}>
          {!imageLoaded && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 140,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <CardMedia
            component='img'
            height='140'
            image={pizza.image}
            alt={pizza.alt}
            style={{ display: imageLoaded ? "block" : "none" }}
            onLoad={() => setImageLoaded(true)}
          />
          <CardContent sx={{ minHeight: 200 }}>
            <Typography
              gutterBottom
              variant='h5'
              component='div'
              color='secondary'
            >
              {pizza.name}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {pizza.ingredients.join(", ")}
            </Typography>
            <Typography variant='h6' color='primary.main' sx={{ mt: 2 }}>
              {`${pizza.price} Ft`}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
      >
        <Box sx={style}>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <img src={pizza.image} alt={pizza.name} style={{ width: "100%" }} />
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default PizzaCard;
